<template>
  <div>
    <div class="row">
      <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
        <a-card>
          <template slot="title">
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                <strong>Balance</strong>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <a-button
                  class="btn btn-outline-primary pull-right"
                  style="min-width: 100px"
                  :loading="loabuttonRefresh"
                  @click="getBalance"
                  > Refresh</a-button
                >
              </div>
            </div>
          </template>
          <div class="row" v-if="balance.view.visible">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label><strong>UID: </strong>{{ balance.view.UID }}</label>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <label><strong>PIN: </strong>{{ balance.view.PIN }}</label>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <label><strong>SALDO: </strong>{{ formatNumber(balance.view.SALDO) }}</label>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <label><strong>STATUS: </strong>{{ balance.view.STATUS }}</label>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <label><strong>KET: </strong>{{ balance.view.KET }}</label>
            </div>
          </div>
        </a-card>
      </div>
      <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
        <a-card>
          <template slot="title">
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                <strong>Transfer Saldo</strong>
              </div>
            </div>
          </template>
          <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label>UID</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-input v-model="form.uid" :readOnly="true" @click="notifEditAtas"></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>PIN</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input v-model="form.pin" :readOnly="true" type="password" @click="notifEditAtas"></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>No Hp</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input v-model="form.no_hp"></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Kode Produk</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                :clearable="false"
                class="style-chooser"
                v-model="form.kode_produk"
                placeholder="Kode Produk"
                label="keterangan"
                :options="masterKodeProduk"
                :reduce="(tes) => tes.kode"
                style="width: 80%"
              />
              <!-- <a-input v-model="form.kode_produk"></a-input> -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label>Ref</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input v-model="form.ref1"></a-input>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <a-button
                class="btn btn-outline-primary pull-right"
                style="min-width: 150px"
                @click="Submit"
                >Submit</a-button
              >
            </div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  created() {
    this.getUid()

    this.getMaster()
  },
  data() {
    return {
      loabuttonRefresh: false,
      masterKodeProduk: [],
      balance: {
        send: {
          method: 'rajabiller.balance',
          uid: '',
          pin: '',
        },
        view: {
          visible: false,
          UID: '',
          PIN: '',
          SALDO: '',
          STATUS: '',
          KET: '',
        },
      },
      form: {
        method: 'rajabiller.game',
        uid: '',
        pin: '',
        no_hp: '',
        kode_produk: '',
        ref1: '',
      },
    }
  },
  methods: {
    moment,
    async getUid() {
      var res = await lou.customUrlGet2('uid')
      if (res) {
        // console.log('res.data.uid', res.data.uid)
        // console.log('this.settings.tempAppData.uid', this.settings.tempAppData.uid)
        this.settings.tempAppData = { uid: '', pin: '' }
        this.settings.tempAppData.uid = res.data === undefined || res.data === null ? '' : res.data.uid === undefined || res.data.uid === null ? '' : res.data.uid
        this.settings.tempAppData.pin = res.data === undefined || res.data === null ? '' : res.data.pin === undefined || res.data.pin === null ? '' : res.data.pin
        this.form.uid = this.settings.tempAppData.uid
        this.form.pin = this.settings.tempAppData.pin

        this.balance.send.uid = this.settings.tempAppData.uid
        this.balance.send.pin = this.settings.tempAppData.pin
      }
    },
    notifEditAtas() {
      lou.shownotif('Informasi', 'Edit uid ada di atas "Set UID"!')
    },
    async getMaster() {
      // console.log('this.user', this.user)
      // this.masterKodeProduk = this.user.master.kode_produk

      var res = await lou.customUrlGet2('produk')
      if (res) {
        this.masterKodeProduk = res.data
      }
    },
    async getBalance() {
      this.loabuttonRefresh = true
      var res = await lou.customUrlPost2('', this.balance.send, false, false, false)
      if (res.data.STATUS === '00') {
        this.balance.view.visible = true
        this.balance.view.UID = res.data.UID
        this.balance.view.PIN = res.data.PIN
        this.balance.view.SALDO = res.data.SALDO
        this.balance.view.STATUS = res.data.STATUS
        this.balance.view.KET = res.data.KET
        this.loabuttonRefresh = false
      } else {
        lou.shownotif('Error', res.data, 'error')
        this.loabuttonRefresh = false
      }
    },
    Submit() {
      if (
        this.form.uid === '' ||
        this.form.pin === '' ||
        this.form.method === '' ||
        this.form.kode_produk === '' ||
        this.form.no_hp === '' ||
        this.form.ref1 === ''
      ) {
        lou.shownotif('Kosong!', 'Input tidak boleh kosong!', 'error')
      } else {
        var data = this.$g.clone(this.form)
        Modal.confirm({
          title: 'Apakah anda yakin data sudah benar?',
          content: 'Aksi tidak dapat dibatalkan!',
          okText: 'Yes',
          okType: 'primary',
          cancelText: 'No',
          onOk: async () => {
            try {
              var res = await lou.customUrlPost2('', data, false, false, true)
              if (res.data.STATUS_TRX === 'GAGAL') {
                lou.shownotif('Gagal!', res.data.KET, 'error')
                // this.getBalance()
              } else {
                lou.shownotif('Info!', res.data.KET)
              }
              this.getBalance()
            } catch (error) {
              // console.log('error', error)
              lou.shownotif('Error', error.toString())
            }
          },
          onCancel: () => {
            // console.log('Cancel')
            return false
          },
        })
      }
    },
    formatNumber(val) {
      return lou.curency(val)
    },
  },
}
</script>

<style lang="scss" scoped></style>
